const mutations = {
  authRequest (state: any) {
    state.status = 'loading'
  },
  authSuccess (state: any, authHeaders: AuthHeaders) {
    state.uid = authHeaders.uid
    state.client = authHeaders.client
    state.accessToken = authHeaders.accessToken
    state.status = 'success'
  },
  authError (state: any) {
    state.status = 'error'
  },
  signout (state: any) {
    state.uid = ''
    state.client = ''
    state.accessToken = ''
    state.currentUser = ''
    state.currentUserId = ''
    state.currentTenantId = ''
    state.currentMemberId = ''
    state.currentPermissions = []
    state.status = ''
  },
  currentUser (state: any, currentUser: string) {
    state.currentUser = currentUser
  },
  currentUserId (state: any, currentUserId: string) {
    state.currentUserId = currentUserId
  },
  currentTenantId (state: any, currentTenantId: string) {
    state.currentTenantId = currentTenantId
  },
  currentMemberId (state: any, currentMemberId: string) {
    state.currentMemberId = currentMemberId
  },
  currentPermissions (state: any, currentPermissions: string) {
    state.currentPermissions = currentPermissions
  }
}

interface AuthHeaders {
  uid: string;
  client: string;
  accessToken: string;
}

export default mutations
