import ApplicationRecord from '@/models/application_record'

import {
  Model,
  Attr
} from 'spraypaint'

@Model()
export default class Color extends ApplicationRecord {
  static jsonapiType = 'colors'

  @Attr name!: string
  @Attr code!: string
  @Attr meta!: { [key: string]: string[] }
}
