export default function capitalize (input: string, maxValue = 15) {
  if (!input) return

  let result = input.substring(0, maxValue)
  if (input.length > maxValue) {
    result += '...'
  }

  return result
}
