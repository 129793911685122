export default function capitalize (input: string) {
  if (!input) return

  const words = input.split(' ')

  const capitalizedWords: string[] = []
  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1))
  }

  return capitalizedWords.join(' ')
}
