import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Home')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login'),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthenticated']) next({ name: 'Home' })
      else next()
    }
  }, {
    path: '/release_notes',
    name: 'Release Notes',
    component: () => import('@/pages/ReleaseNotes')
  }, {
    path: '/accounting/cashflow',
    name: 'Cash Flow',
    component: () => import('@/pages/CashFlow')
  }, {
    path: '/accounting/forecast',
    name: 'Forecast',
    component: () => import('@/pages/Forecast')
  }, {
    path: '/accounting/accounts',
    name: 'Accounts',
    component: () => import('@/pages/Accounts')
  }, {
    path: '/accounting/reports',
    name: 'Reports',
    component: () => import('@/pages/Reports')
  }, {
    path: '/investing/investments',
    name: 'Investments',
    component: () => import('@/pages/Investments')
  }, {
    path: '/investing/contributions_and_distributions',
    name: 'Contributions and Distributions',
    component: () => import('@/pages/ContributionsAndDistributions')
  }, {
    path: '/project_management/projects',
    name: 'Projects',
    component: () => import('@/pages/Projects')
  }, {
    path: '/admin/users',
    name: 'Manage Users',
    component: () => import('@/pages/ManageUsers')
  }, {
    path: '/admin/audits',
    name: 'Audit Log',
    component: () => import('@/pages/AuditLog')
  }, {
    path: '/preferences',
    name: 'Preferences',
    component: () => import('@/pages/Preferences')
  }
]

const router = new VueRouter({
  // TODO: re-enable history mode once server is
  //  properly configured to handle it
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function verifyAuthenticationStatus () {
  try {
    await Vue.prototype.$api.get('api/v1/users/current', {
      headers: {
        'access-token': store.getters['auth/accessToken'],
        client: store.getters['auth/client'],
        uid: store.getters['auth/uid']
      }
    })
  } catch (e) {
    store.dispatch('auth/signout')
    router.push('/')
  }
}

function setAuthHeaders () {
  Vue.prototype.$api.defaults.headers.common.uid = store.getters['auth/uid']
  Vue.prototype.$api.defaults.headers.common.client = store.getters['auth/client']
  Vue.prototype.$api.defaults.headers.common['access-token'] = store.getters['auth/accessToken']
}

router.beforeEach((to, from, next) => {
  setAuthHeaders()
  if (to.name !== 'Login' && !store.getters['auth/isAuthenticated']) next({ name: 'Login' })
  else next()
})

router.afterEach(async (to, from) => {
  if (!from.name || to.name === 'Login') return
  verifyAuthenticationStatus()
})

export default router
