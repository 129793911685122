import Vue from 'vue'
import App from './App.vue'

// vue-router
import router from './router'

// vuex
import store from './store'

// http library
import axios, { AxiosStatic } from 'axios'

// custom filters
import filters from '@/filters'

// component library core dependencies
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

// component library extra feature dependencies
import ToastService from 'primevue/toastservice'

// import component library theme based on environment
import('primevue/resources/themes/' + process.env.VUE_APP_THEME + '/theme.css')

// disable console warning in dev mode
Vue.config.productionTip = false

// disable console warnings from vue
Vue.config.silent = true

// enable ripple effect for component library
Vue.prototype.$primevue = { ripple: true }

// set axios config and give it an alias
Vue.prototype.$api = axios.create({
  baseURL: process.env.VUE_APP_NORTH_STAR_API_ENDPOINT
})

// use custom filters defined in @/filters
Vue.use(filters)

// use extra features from component library
Vue.use(ToastService)

// validate existing tokens when app is initialized
if (!store.getters.isAuthenticated) {
  if (router.currentRoute.path !== '/') {
    router.push('/')
  }
}
if (store.getters.isAuthenticated) {
  store.dispatch('validateToken')
    .catch(() => {
      if (router.currentRoute.path !== '/') {
        router.push('/')
      }
    })
}

// enables usage of aliases within components
//   e.g., instead of importing axios within the
//   component, setting a baseUrl, headers, and then
//   calling axios.get(...), we can just set config
//   vars here in main, and call this.$api.get(...)
declare module 'vue/types/vue' {
  interface Vue {
    $api: AxiosStatic;
  }
}

// init app
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
