import dayjs from 'dayjs'

const dailyTaskGroups = () => {
  const today = dayjs()
  return [
    { title: 'Past', tasks: [], startDate: null, endDate: today.subtract(1, 'day').format('YYYY-MM-DD') },
    { title: 'Today', tasks: [], startDate: today.format('YYYY-MM-DD'), endDate: today.format('YYYY-MM-DD') },
    { title: 'Tomorrow', tasks: [], startDate: today.add(1, 'day').format('YYYY-MM-DD'), endDate: today.add(1, 'day').format('YYYY-MM-DD') },
    { title: today.add(2, 'day').format('dddd'), tasks: [], startDate: today.add(2, 'day').format('YYYY-MM-DD'), endDate: today.add(2, 'day').format('YYYY-MM-DD') },
    { title: today.add(3, 'day').format('dddd'), tasks: [], startDate: today.add(3, 'day').format('YYYY-MM-DD'), endDate: today.add(3, 'day').format('YYYY-MM-DD') },
    { title: today.add(4, 'day').format('dddd'), tasks: [], startDate: today.add(4, 'day').format('YYYY-MM-DD'), endDate: today.add(4, 'day').format('YYYY-MM-DD') },
    { title: today.add(5, 'day').format('dddd'), tasks: [], startDate: today.add(5, 'day').format('YYYY-MM-DD'), endDate: today.add(5, 'day').format('YYYY-MM-DD') },
    { title: today.add(6, 'day').format('dddd'), tasks: [], startDate: today.add(6, 'day').format('YYYY-MM-DD'), endDate: today.add(6, 'day').format('YYYY-MM-DD') },
    { title: 'Future', tasks: [], startDate: today.add(7, 'day').format('YYYY-MM-DD'), endDate: null }
  ]
}

const weeklyTaskGroups = () => {
  const today = dayjs()
  return [
    { title: 'Past', tasks: [], startDate: null, endDate: today.startOf('week').subtract(1, 'day').format('YYYY-MM-DD') },
    { title: 'This Week', tasks: [], startDate: today.startOf('week').format('YYYY-MM-DD'), endDate: today.endOf('week').startOf('day').format('YYYY-MM-DD') },
    { title: 'Next Week', tasks: [], startDate: today.add(1, 'week').startOf('week').format('YYYY-MM-DD'), endDate: today.add(1, 'week').endOf('week').startOf('day').format('YYYY-MM-DD') },
    { title: 'Future', tasks: [], startDate: today.add(1, 'week').endOf('week').add(1, 'day').format('YYYY-MM-DD'), endDate: null }
  ]
}

const monthlyTaskGroups = () => {
  const today = dayjs()
  return [
    { title: 'Past', tasks: [], startDate: null, endDate: today.startOf('month').subtract(1, 'day').format('YYYY-MM-DD') },
    { title: 'This Month', tasks: [], startDate: today.startOf('month').format('YYYY-MM-DD'), endDate: today.endOf('month').startOf('day').format('YYYY-MM-DD') },
    { title: 'Next Month', tasks: [], startDate: today.add(1, 'month').startOf('month').format('YYYY-MM-DD'), endDate: today.add(1, 'month').endOf('month').startOf('day').format('YYYY-MM-DD') },
    { title: 'Future', tasks: [], startDate: today.add(1, 'month').endOf('month').add(1, 'day').format('YYYY-MM-DD'), endDate: null }
  ]
}

const defaultTaskGroups = () => {
  return [
    { title: 'All Tasks', tasks: [] }
  ]
}

export const getTaskGroups = (type: string) => {
  switch (type) {
    case 'daily':
      return dailyTaskGroups()
    case 'weekly':
      return weeklyTaskGroups()
    case 'monthly':
      return monthlyTaskGroups()
    default:
      return defaultTaskGroups()
  }
}
