import { getTaskGroups } from './services/getTaskGroups'

const state = {
  taskGroups: {
    default: getTaskGroups('default'),
    daily: getTaskGroups('daily'),
    weekly: getTaskGroups('weekly'),
    monthly: getTaskGroups('monthly')
  }
}

const getters = {
  taskGroups: (state: any) => state.taskGroups
}

export default {
  namespaced: true,
  state,
  getters
}
