import dayjs from 'dayjs'

export default function appTime (input: string, ignoreValues: string[] = []) {
  if (ignoreValues.includes(input)) return input
  const result = dayjs(input).format('h:mm A')
  if (result === 'Invalid Date') {
    return ''
  }
  return result
}
