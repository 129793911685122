import actions from './actions'
import mutations from './mutations'

const state = {
  uid: localStorage.getItem('uid') || '',
  client: localStorage.getItem('client') || '',
  accessToken: localStorage.getItem('access-token') || '',
  currentUser: localStorage.getItem('current-user') || '',
  currentUserId: localStorage.getItem('current-user-id') || '',
  currentTenantId: localStorage.getItem('current-tenant-id') || '',
  currentMemberId: localStorage.getItem('current-member-id') || '',
  currentPermissions: localStorage.getItem('current-permissions')
    ? localStorage.getItem('current-permissions')!.split(',')
    : [],
  status: ''
}

const getters = {
  isAuthenticated: (state: any) => !!state.accessToken,
  authStatus: (state: any) => state.status,
  uid: (state: any) => state.uid,
  client: (state: any) => state.client,
  accessToken: (state: any) => state.accessToken,
  currentUser: (state: any) => state.currentUser,
  currentUserId: (state: any) => state.currentUserId,
  currentTenantId: (state: any) => state.currentTenantId,
  currentMemberId: (state: any) => state.currentMemberId,
  currentPermissions: (state: any) => state.currentPermissions
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
