import { VueConstructor } from 'vue/types/umd'

import capitalize from '@/filters/capitalize'
import daysSince from '@/filters/days_since'
import deSnake from '@/filters/de_snake'
import maxCharacters from '@/filters/max_characters'
import splitOnCaps from '@/filters/split_on_caps'

import appDate from '@/filters/app_date'
import appDateShort from '@/filters/app_date_short'
import appDraftType from '@/filters/app_draft_type'
import appCurrency from '@/filters/app_currency'
import appPercent from '@/filters/app_percent'
import appTime from '@/filters/app_time'

export default {
  install (Vue: VueConstructor) {
    Vue.filter('capitalize', capitalize)
    Vue.filter('daysSince', daysSince)
    Vue.filter('deSnake', deSnake)
    Vue.filter('maxCharacters', maxCharacters)
    Vue.filter('splitOnCaps', splitOnCaps)

    Vue.filter('appDate', appDate)
    Vue.filter('appDateShort', appDateShort)
    Vue.filter('appDraftType', appDraftType)
    Vue.filter('appCurrency', appCurrency)
    Vue.filter('appPercent', appPercent)
    Vue.filter('appTime', appTime)
  }
}
