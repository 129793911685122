import {
  Model,
  SpraypaintBase,
  MiddlewareStack
} from 'spraypaint'

type ForeignRecordGroup = { [key: string]: ApplicationRecord | ApplicationRecord[] }

const middleware = new MiddlewareStack()

middleware.beforeFilters.push((url: string, options: any) => {
  // get auth headers from local storage
  options.headers.uid = localStorage.getItem('uid')
  options.headers.client = localStorage.getItem('client')
  options.headers['access-token'] = localStorage.getItem('access-token')
})

@Model()
export default class ApplicationRecord extends SpraypaintBase {
  static baseUrl = process.env.VUE_APP_NORTH_STAR_API_ENDPOINT
  static apiNamespace = '/api/v1'
  static clientApplication = 'north-star-web-client'
  static middlewareStack = middleware

  static async saveRecords <T extends ApplicationRecord> (recordArray: T[]) {
    for await (const record of recordArray) {
      await record.save()
    }
  }

  static async deleteRecords <T extends ApplicationRecord> (recordArray: T[]) {
    for await (const record of recordArray) {
      if (record?.id) record.destroy()
    }
  }

  static async destroyRecordAtIndex <T extends ApplicationRecord> (recordArray: T[], index: number) {
    await recordArray[index].destroy()
    const newRecords = [...recordArray]
    newRecords.splice(index, 1)
    return newRecords
  }

  static duplicateRecords <T extends ApplicationRecord> (recordArray: T[]) {
    return recordArray.map(record => record.dup())
  }

  static findRecordInArray <T extends ApplicationRecord> (recordArray: T[], options: { [key: string]: any }) {
    const result = recordArray.find(record => {
      const anyRecord = record as any
      let isRequestedRecord = true
      for (const attr in options) {
        if (anyRecord[attr] !== options[attr]) {
          isRequestedRecord = false
        }
      }
      return isRequestedRecord
    })
    return result
  }

  restoreInitialState () {
    if (this.isDirty()) this.restoreInitialAttributeValues()
    this.restoreAttachedForeignRecords()
  }

  restoreInitialAttributeValues (attributes?: string[]) {
    const instance: any = (() => this)()
    const attributesToRestore = attributes || Object.keys(this.changes())
    for (const [attr, value] of Object.entries(this.changes())) {
      if (attributesToRestore.includes(attr)) {
        const originalValue = value![0]
        instance[attr] = originalValue
      }
    }
  }

  restoreAttachedForeignRecords () {
    const propertyDescription = Object.getOwnPropertyDescriptor(this, 'relationships')
    if (propertyDescription) {
      const foreignRecordGroup: ForeignRecordGroup = propertyDescription.value
      for (const [key, attachment] of Object.entries(foreignRecordGroup)) {
        if (Array.isArray(attachment)) {
          attachment.forEach(record => record.restoreInitialState())
        } else {
          attachment.restoreInitialState()
        }
      }
    }
  }
}
