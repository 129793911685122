










































import { Component, Watch, Vue } from 'vue-property-decorator'
import { Getter, Action, namespace } from 'vuex-class'

import Menubar from 'primevue/menubar'
import PanelMenu from 'primevue/panelmenu'
import Menu from 'primevue/menu'
import Button from 'primevue/button'
import Toast from 'primevue/toast'

Vue.component('Menubar', Menubar)
Vue.component('PanelMenu', PanelMenu)
Vue.component('Menu', Menu)
Vue.component('Button', Button)
Vue.component('Toast', Toast)

const Auth = namespace('auth')

@Component
export default class App extends Vue {
  @Auth.Getter currentUser!: string
  @Auth.Getter isAuthenticated!: string
  @Auth.Getter currentPermissions!: string
  @Auth.Action('signout') dispatchSignout!: Function

  version: string = process.env.VUE_APP_VERSION
  environment: string = process.env.VUE_APP_ENV

  processing = false

  userMenuItems: object[] = [
    {
      label: 'Preferences',
      to: '/preferences'
    },
    {
      label: 'Sign Out',
      command: this.signout
    }
  ]

  get menuItems () {
    const menuItems: any[] = []

    if (this.showAccountingItem) {
      const accountingItem: any = {
        label: 'Accounting',
        icon: 'pi pi-briefcase',
        items: []
      }

      if (this.isAllowedRoute('/accounting/cashflow')) {
        accountingItem.items.push({
          label: 'Cash Flow',
          to: '/accounting/cashflow'
        })
      }
      if (this.isAllowedRoute('/accounting/forecast')) {
        accountingItem.items.push({
          label: 'Forecast',
          to: '/accounting/forecast'
        })
      }
      if (this.isAllowedRoute('/accounting/accounts')) {
        accountingItem.items.push({
          label: 'Accounts',
          to: '/accounting/accounts'
        })
      }
      if (this.isAllowedRoute('/accounting/reports')) {
        accountingItem.items.push({
          label: 'Reports',
          to: '/accounting/reports'
        })
      }
      menuItems.push(accountingItem)
    }

    if (this.showInvestingItem) {
      const investingItem: any = {
        label: 'Investing',
        icon: 'pi pi-chart-line',
        items: []
      }
      if (this.isAllowedRoute('/investing/investments')) {
        investingItem.items.push({
          label: 'Investments',
          to: '/investing/investments'
        })
      }
      if (this.isAllowedRoute('/investing/contributions_and_distributions')) {
        investingItem.items.push({
          label: 'Contributions & Distributions',
          to: '/investing/contributions_and_distributions'
        })
      }
      menuItems.push(investingItem)
    }

    if (this.showProjectManagementItem) {
      const projectManagementItem: any = {
        label: 'Project Management',
        icon: 'pi pi-sitemap',
        items: []
      }
      if (this.isAllowedRoute('/project_management/projects')) {
        projectManagementItem.items.push({
          label: 'Projects',
          to: '/project_management/projects'
        })
      }
      menuItems.push(projectManagementItem)
    }

    if (this.showAdminItem) {
      const adminItem: any = {
        label: 'Admin',
        icon: 'pi pi-users',
        items: []
      }
      if (this.isAllowedRoute('/admin/users')) {
        adminItem.items.push({
          label: 'Manage Users',
          to: '/admin/users'
        })
      }
      if (this.isAllowedRoute('/admin/audits')) {
        adminItem.items.push({
          label: 'Audit Log',
          to: '/admin/audits'
        })
      }
      menuItems.push(adminItem)
    }

    return menuItems
  }

  get showAccountingItem () {
    return this.isAllowedRoute('/accounting/cashflow') ||
      this.isAllowedRoute('/accounting/forecast') ||
      this.isAllowedRoute('/accounting/accounts')
  }

  get showInvestingItem () {
    return this.isAllowedRoute('/investing/investments') ||
      this.isAllowedRoute('/investing/contributions_and_distributions')
  }

  get showProjectManagementItem () {
    return this.isAllowedRoute('/project_management/projects')
  }

  get showAdminItem () {
    return this.isAllowedRoute('/admin/users') ||
      this.isAllowedRoute('/admin/audits')
  }

  created () {
    this.verifyAuthenticationStatus()
  }

  async verifyAuthenticationStatus () {
    try {
      await Vue.prototype.$api.get('api/v1/users/current', {
        headers: {
          'access-token': this.$store.getters['auth/accessToken'],
          client: this.$store.getters['auth/client'],
          uid: this.$store.getters['auth/uid']
        }
      })
    } catch (e) {
      this.$store.dispatch('auth/signout')
      this.$router.push('/')
    }
  }

  @Watch('$route')
  onRouteChanged (to: any, from: any) {
    if (from.path === '/login') {
      // TO DO: fix this so page doesn't have to be reloaded on login...
      // this is currently here to resolve an issue in which menu items do
      // not appear after a user logs in
      location.reload()
    }
    if (!this.isAllowedRoute(to.path)) {
      this.$router.push('/')
    }
  }

  isAllowedRoute (routeName: string) {
    const permissions = this.currentPermissions
    switch (routeName) {
      case '/accounting/cashflow':
        return permissions.includes('read_account') &&
          permissions.includes('read_account_transaction')
      case '/accounting/forecast':
        return permissions.includes('read_account') &&
          permissions.includes('read_account_transaction')
      case '/accounting/accounts':
        return permissions.includes('read_account')
      case '/accounting/reports':
        return permissions.includes('read_account')
      case '/investing/investments':
        return permissions.includes('read_investment')
      case '/investing/contributions_and_distributions':
        return permissions.includes('read_investment') &&
          permissions.includes('read_investment_transaction')
      case '/project_management/projects':
        return permissions.includes('read_company') &&
          permissions.includes('read_project')
      case '/admin/users':
        return permissions.includes('read_user')
      case '/admin/audits':
        return permissions.includes('read_version')
      case '/release_notes':
        return true
      case '/preferences':
        return true
      default:
        return false
    }
  }

  toggleUserMenu (event: object) {
    (this.$refs.user_menu as Vue & { toggle: (event: object) => boolean }).toggle(event)
  }

  goHome () {
    if (this.$router.currentRoute.name !== 'Home') {
      this.$router.push({ name: 'Home' })
    }
  }

  async signout () {
    this.processing = true

    try {
      await this.dispatchSignout()
      this.$router.push('/login')
    } catch (err) {
      // TODO: implement error dialog
    }
    this.processing = false
    this.$router.push('/login')
  }
}
