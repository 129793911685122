import Color from '@/models/color'

import actions from './actions'

const state = {
  colors: []
}

const mutations = {
  colors (state: any, colors: Color[]) {
    state.colors = colors
  }
}

const getters = {
  colors: (state: any) => state.colors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
