import Color from '@/models/color'

const actions = {
  async colors ({ commit }: any) {
    const colors = (await Color
      .per(10000)
      .all()
    ).data
    commit('colors', colors)
  }
}

export default actions
