import accounting from 'accounting'

accounting.settings.currency.format = {
  pos: '%s%v',
  neg: '(%s%v)',
  zero: '%s%v'
}

export default function appCurrency (input: string, ignoreValues: string[] = [], allowZero = false) {
  if (ignoreValues.includes(input)) return input
  if (String(input) === '0' && allowZero) return '$0.00'
  if (!input) return
  return accounting.formatMoney(input)
}
