import Vue from 'vue'

const actions = {
  signin ({ commit }: any, credentials: any) {
    return new Promise((resolve, reject) => {
      commit('authRequest')

      Vue.prototype.$api.post('auth/sign_in', credentials)
        .then((resp: NSApiAuthResponse) => {
          // do not store auth vars if server response did not include them
          if (!resp.headers.uid || !resp.headers.client || !resp.headers['access-token']) {
            commit('authError')
            reject(resp)
          }

          const authHeaders = {
            uid: resp.headers.uid,
            client: resp.headers.client,
            accessToken: resp.headers['access-token']
          }

          // set auth vars in local storage
          localStorage.setItem('uid', authHeaders.uid)
          localStorage.setItem('client', authHeaders.client)
          localStorage.setItem('access-token', authHeaders.accessToken)

          // set auth vars in default http headers for $api
          Vue.prototype.$api.defaults.headers.common.uid = authHeaders.uid
          Vue.prototype.$api.defaults.headers.common.client = authHeaders.client
          Vue.prototype.$api.defaults.headers.common['access-token'] = authHeaders.accessToken

          // get current user metadata and permissions upon successful login
          Vue.prototype.$api.get('api/v1/users/current')
            .then((resp: NSApiAuthResponse) => {
              const currentUser = resp.data.first_name
              const currentUserId = resp.data.id
              const currentTenantId = resp.data.current_tenant_id

              localStorage.setItem('current-user', currentUser)
              commit('currentUser', currentUser)

              localStorage.setItem('current-user-id', currentUserId)
              commit('currentUserId', currentUserId)

              localStorage.setItem('current-tenant-id', currentTenantId)
              commit('currentTenantId', currentTenantId)

              if (currentUserId && currentTenantId) {
                Vue.prototype.$api.get('api/v1/memberships?filter[user_id]=' + currentUserId + '&filter[tenant_id]=' + currentTenantId)
                  .then((resp: any) => {
                    const currentMemberId = resp.data.data[0].id

                    localStorage.setItem('current-member-id', currentMemberId)
                    commit('currentMemberId', currentMemberId)

                    Vue.prototype.$api.get('api/v1/memberships/' + currentMemberId + '/permissions')
                      .then((resp: any) => {
                        const currentPermissions = resp.data.map((item: any) => {
                          return item.name
                        })

                        localStorage.setItem('current-permissions', currentPermissions)
                        commit('currentPermissions', currentPermissions)

                        // set auth vars in vuex store
                        commit('authSuccess', authHeaders)
                        resolve(resp)
                      })
                  })
              }
            })
        })
        .catch((err: NSApiAuthResponse) => {
          commit('authError')

          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
          localStorage.removeItem('current-user')
          localStorage.removeItem('current-user-id')
          localStorage.removeItem('current-tenant-id')
          localStorage.removeItem('current-member-id')
          localStorage.removeItem('current-permissions')

          reject(err)
        })
    })
  },
  signout ({ commit }: any) {
    return new Promise((resolve) => {
      commit('signout')

      Vue.prototype.$api.delete('auth/sign_out')
        .catch((err: NSApiAuthResponse) => {
          // eslint-disable-next-line
          console.error(err)
        })

      localStorage.removeItem('uid')
      localStorage.removeItem('client')
      localStorage.removeItem('access-token')
      localStorage.removeItem('current-user')
      localStorage.removeItem('current-user-id')
      localStorage.removeItem('current-tenant-id')
      localStorage.removeItem('current-member-id')
      localStorage.removeItem('current-permissions')

      delete Vue.prototype.$api.defaults.headers.common.uid
      delete Vue.prototype.$api.defaults.headers.common.client
      delete Vue.prototype.$api.defaults.headers.common['access-token']

      resolve(null)
    })
  },
  validateToken ({ commit }: any) {
    return new Promise((resolve, reject) => {
      const uid = localStorage.getItem('uid')
      const client = localStorage.getItem('client')
      const accessToken = localStorage.getItem('access-token')

      const authParams = {
        uid: uid,
        client: client,
        'access-token': accessToken
      }

      // temporarily remove default auth headers for the validate_token call
      //   since they are not required by devise_token_auth
      delete Vue.prototype.$api.defaults.headers.common.uid
      delete Vue.prototype.$api.defaults.headers.common.client
      delete Vue.prototype.$api.defaults.headers.common['access-token']

      Vue.prototype.$api.get('auth/validate_token', { params: authParams })
        .then(() => {
          // token is valid. re-set the default auth headers
          Vue.prototype.$api.defaults.headers.common.uid = uid
          Vue.prototype.$api.defaults.headers.common.client = client
          Vue.prototype.$api.defaults.headers.common['access-token'] = accessToken

          resolve()
        })
        .catch((err: NSApiAuthResponse) => {
          // token is invalid. sign out and remove all auth vars/headers
          commit('signout')

          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
          localStorage.removeItem('current-user')
          localStorage.removeItem('current-user-id')
          localStorage.removeItem('current-tenant-id')
          localStorage.removeItem('current-member-id')
          localStorage.removeItem('current-permissions')

          delete Vue.prototype.$api.defaults.headers.common.uid
          delete Vue.prototype.$api.defaults.headers.common.client
          delete Vue.prototype.$api.defaults.headers.common['access-token']

          reject(err)
        })
    })
  }
}

interface NSApiAuthResponse {
  data: {
    id: string;
    first_name: string;
    last_name: string;
    current_tenant_id: string;
  };
  headers: {
    uid: string;
    client: string;
    'access-token': string;
  };
}

export default actions
